import React from 'react'
import HTMl from "../images/stack/HTML.png"
import CSS from "../images/stack/CSS.png"
import Javascript from "../images/stack/Javascript.svg"
import Express from "../images/stack/Express.png"
import NodeJs from "../images/stack/NodeJs.svg"
import MongoDB from "../images/stack/MongoDB.svg"
import Redux from "../images/stack/Redux.svg"
import Vercel from "../images/stack/Vercel.svg"

const Projects = () => {
    return (
        <div className="project-section" id="projects">
            <h2 className="page-header">Projects</h2>

            <div className="project-container">
                <div className="project-card" id="project1">
                    <div className="project-number project-number-right">01</div>
                    <div className="project-content project-content-left">

                        <div className="project-skills-container">
                            <img className="project-skill" src={HTMl} alt="" />
                            <img className="project-skill" src={CSS} alt="" />
                            <img className="project-skill" src={Javascript} alt="" />
                            <img className="project-skill" src={Express} alt="" />
                            <img className="project-skill" src={NodeJs} alt="" />
                            <img className="project-skill" src={MongoDB} alt="" />
                            <img className="project-skill" src={Redux} alt="" />
                            <img className="project-skill" src={Vercel} alt="" />
                        </div>

                        <h2 className="project-heading">Course Craft</h2>

                        <p className="project-subHeading">Developed a fully functional Ed-Tech platform where instructors can create courses. Integrated Razorpay payment API to facilitate course purchases for students. Enabled user registration as either instructors or students
                        </p>
                        <div className="flex gap-4">
                            <a href="https://github.com/adityanama/Course-Craft" target="_blank">
                                <i title="GitHub Link" className="fa-brands fa-github icon"></i>
                            </a>
                            <a href="https://coursecraft.adityanama.online/" target="_blank">
                                <i title="Live Link" className="fa-solid fa-link icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="project-card" id="project2">
                    <div className="project-number project-number-right">02</div>
                    <div className="project-content project-content-left">
                        <div className="project-skills-container">
                            <img className="project-skill" src={HTMl} alt="" />
                            <img className="project-skill" src={CSS} alt="" />
                            <img className="project-skill" src={Javascript} alt="" />
                            <img className="project-skill" src={Express} alt="" />
                            <img className="project-skill" src={NodeJs} alt="" />
                            <img className="project-skill" src={MongoDB} alt="" />
                            <img className="project-skill" src={Redux} alt="" />
                            <img className="project-skill" src={Vercel} alt="" />
                        </div>
                        <h2 className="project-heading">Play Haven</h2>
                        <p className="project-subHeading">
                            Developed an online store where users can browse a variety of video games. Implemented features that allow users to create accounts and securely purchase games. Provided functionality for users to edit profile, view purchased games, etc
                        </p>
                        <div className="flex gap-4">
                            <a href="https://github.com/adityanama/Play-Haven" target="_blank">
                                <i title="GitHubLink" className="fa-brands fa-github icon"></i>
                            </a>
                            <a href="https://play-haven.adityanama.online/" target="_blank">
                                <i title="Live Link" className="fa-solid fa-link icon"></i>
                            </a>
                        </div>
                    </div>
                </div>

                <div className="project-card" id="project3">
                    <div className="project-number project-number-right">03</div>
                    <div className="project-content project-content-left">
                        <div className="project-skills-container">
                            <img className="project-skill" src={HTMl} alt="" />
                            <img className="project-skill" src={CSS} alt="" />
                            <img className="project-skill" src={Javascript} alt="" />
                            <img className="project-skill" src={Vercel} alt="" />
                        </div>

                        <h2 className="project-heading">Tic Tac Toe</h2>
                        <p className="project-sub-heading">
                            Developed an interactive Tic-Tac-Toe game <br /> website  with real-time gameplay
                        </p>
                        <div className="flex gap-4">
                            <a href="https://github.com/adityanama/Tic-Tac-Toe" target="_blank">
                                <i title="GitHubLink" className="fa-brands fa-github icon"></i>
                            </a>
                            <a href="http://tic-tac-toe.adityanama.online" target="_blank">
                                <i title="Live Link" className="fa-solid fa-link icon"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Projects