import './App.css';
import Contact from './Components/Contact';
import Hero from './Components/Hero';
import NavBar from './Components/NavBar';
import Projects from './Components/Projects';
import Skills from './Components/Skills';
import WorkExp from './Components/WorkExp';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
    return (
        <div className='wrapper'>
            <NavBar />
            <Hero />
            <WorkExp />
            <Projects />
            <Skills/>
            <Contact/>
        </div>
    );
}

export default App;
