import React from 'react'

const Contact = () => {

    const links = [
        {
            img: 'https://img.icons8.com/?size=100&id=9L16NypUzu38&format=png&color=000000',
            name: 'Leetcode',
            link: 'https://leetcode.com/u/Aditya_nama/'

        },
        {
            img: 'https://img.icons8.com/?size=100&id=3tC9EQumUAuq&format=png&color=000000',
            name: 'GitHub',
            link: 'https://github.com/adityanama/'

        },
        {
            img: 'https://img.icons8.com/?size=100&id=xuvGCOXi8Wyg&format=png&color=000000',
            name: 'LinkedIn',
            link: 'https://www.linkedin.com/in/aditya-nama-02a95b252/'

        },
        {
            img: 'https://img.icons8.com/?size=100&id=5MQ0gPAYYx7a&format=png&color=000000',
            name: 'Twitter',
            link: 'https://x.com/AdityaNama3'

        },
    ]

    return (
        <div className='bg-[rgb(231,231,231)] mt-16 pb-24' id='contactme'>
            <h1 className='page-header'>Let's Connect</h1>
            <div className='w-7/12 mx-auto h-fit border-2 border-black rounded-lg mt-8 p-8 flex flex-col gap-6'>
                <div className='flex gap-4 items-center'>
                    <img src='https://img.icons8.com/?size=100&id=i3XElI5CmcBP&format=png&color=000000' width={40} />
                    <p className='text-2xl font-semibold'>Email - <a href='mailto:adityanama1207@gmail.com'><span className='underline text-blue-600 font-medium'>adityanama1207@gmail.com</span></a></p>
                </div>

                {
                    links.map((val, index) => (
                        <div className='flex gap-4 items-center' key={index}>
                            <img src={val.img} width={40}/>
                            <a href={val.link} className='text-2xl font-semibold'>{val.name}</a>    
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Contact