import React from 'react'
import HTML from "../images/stack/HTML.png"
import CSS from "../images/stack/CSS.png"
import Javascript from "../images/stack/Javascript.svg"
import Express from "../images/stack/Express.png"
import NodeJs from "../images/stack/NodeJs.svg"
import MongoDB from "../images/stack/MongoDB.svg"
import Redux from "../images/stack/Redux.svg"
import Tailwind from '../images/stack/Tailwind.png'
import react from '../images/stack/React.png'
import blob from "../images/userAsset/blob vector.png"


const Skills = () => {
    return (
        <div id="skills" className="w-10/12 mx-auto mt-8">
            <h1 className="page-header">Skills</h1>

            <div className='flex justify-between items-center'>
                <div className="flex flex-col w-8/12 mt-10">
                    <h2 className="skill-heading">
                        <span className="caps">M</span>e and
                        <br />
                        MyTech Stack
                    </h2>
                    <p className="skill-subHeading text-[19px]">
                        As a dedicated and proactive software development intern, I have honed my skills in frontend development through diverse and challenging projects. My experience at Decimal Point Analytics allowed me to master React Testing Library (RTL) and Jest, where I successfully tested the IT inventory website, ensuring its seamless functionality. My passion for creating impactful and user-centric applications is reflected in my projects like Course Craft and Play Haven, where I utilized modern technologies to develop functional and engaging platforms. I am committed to continuous learning and excellence, as evidenced by my good academic background and achievements in competitive coding on platforms like LeetCode.
                    </p>

                </div>

                <div className="flex flex-wrap h-fit gap-x-8 w-5/12 gap-y-8 mt-12 relative">
                    <img src={blob} class="blob-style" alt="" />

                    <img className="project-skill skills-logo" src='https://img.icons8.com/?size=100&id=40670&format=png&color=000000' title='C' />
                    <img className="project-skill skills-logo scale-110" src='https://img.icons8.com/?size=100&id=40669&format=png&color=000000' title="C++" />
                    <img className="project-skill skills-logo" src='https://img.icons8.com/?size=100&id=13441&format=png&color=000000' title="Python" />
                    <img className="project-skill skills-logo" src={HTML} title="HTML" />
                    <img className="project-skill skills-logo" src={CSS} title="CSS" />
                    <img className="project-skill skills-logo" src={Javascript} title="Javascript" />
                    <img className="project-skill skills-logo" src={react} title="React" />
                    <img className="project-skill skills-logo" src={Tailwind} title="Tailwind" />
                    <img className="project-skill skills-logo" src={Express} title="Express" />
                    <img className="project-skill skills-logo" src={NodeJs} title="Node Js" />
                    <img className="project-skill skills-logo" src={MongoDB} title="Mongo DB" />
                    <img className="project-skill skills-logo" src={Redux} title="Redux" />
                </div>
            </div>
        </div>
    )
}

export default Skills