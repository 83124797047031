import React, { useEffect } from 'react'
import Typed from 'typed.js';

const Hero = () => {

    useEffect(() => {
        const options = {
            strings: [
                "Full Stack Developer",
                "Web Developer",
                "Backend Developer",
                "Coder",
            ],
            loop: true,
            typeSpeed: 50,
            backSpeed: 20,
            backDelay: 1000,
        };
        const typed = new Typed(".role", options);
        return () => {
            typed.destroy();
        };
    }, []);

    return (
        <div className='pt-24 mx-auto pb-16 bg-[rgb(231,231,231)]'>
            <div class="hero-section-left">
                <div class="hero-section-heading">Aditya Nama</div>

                <div class="hero-section-heading hero-section-sub-heading">
                    I am a <span class="role"></span>
                </div>

                <div class="mt-[1rem] w-[70%] text-xl leading-8 text-justify">
                    I am currently a fourth-year B.Tech undergraduate student in Electrical Engineering at the Indian Institute of Technology, Gandhinagar. 
                </div>
            </div>
        </div>

    )
}

export default Hero