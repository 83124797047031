import React from 'react'

const WorkExp = () => {
    return (
        <div id='workexp'>
            <h1 className='page-header'>Work Experience</h1>
            <div className='flex flex-col w-9/12 mx-auto mt-12 gap-2 border p-10 border-gray-400 rounded-lg'>
                <div className='flex justify-between items-center'>
                    <h1 className='text-4xl font-semibold'>1. Decimal Point Analytics</h1>
                    <p className='text-lg'>May 2024- July 2024</p>
                </div>
                <div className='flex justify-between items-center'>
                    <h4 className='italic text-xl ml-6'>Software Development Intern</h4>
                    <p className='text-lg'>Remote</p>
                </div>
                <ul className='list-disc ml-6 text-[20px] space-y-2 mt-4'>
                    <li>Acquired proficiency in React Testing Library (RTL) and Jest </li>
                    <li>Conducted a comparative analysis between RTL and Jest.</li>
                    <li>Tested the company's IT inventory website using RTL, ensuring all functionalities worked as intended.</li>
                    <li>Successfully completed the testing of the website, contributing to the project's overall success.</li>
                    <li>Presented my work to the frontend team, explaining RTL and how it was utilized in the testing process.</li>
                    <li>Utilized and strengthened skills in React, React Testing Library, and Jest.</li>
                </ul>
            </div>
        </div >
    )
}

export default WorkExp